import React from 'react';
import { Button, Divider, Flex, FormControl, FormLabel, Input, useToast } from '@chakra-ui/react';
import { colors } from 'styles/colors';

import { isProduction } from 'config';
import { copyToClipboard } from 'utils';

const PATTERNS_IP = isProduction ? '34.30.86.189' : '34.134.151.170';

const PatternsIP = () => {
  const toast = useToast();
  return (
    <>
      <Divider style={{ borderColor: colors.gray[400] }} my={3} />
      <FormControl isRequired={false}>
        <FormLabel mb={2}>
          For Patterns to access your database you likely need to allowlist our IP address in your
          database.
        </FormLabel>
        <Flex display={{ base: 'block', sm: 'flex' }}>
          <FormLabel minWidth={'150px'} my={'auto'}>
            Patterns' IP Address
          </FormLabel>
          <Input
            isReadOnly={true}
            backgroundColor={colors.gray[50]}
            _focus={{ borderColor: colors.gray[200] }}
            value={PATTERNS_IP}
          />
          <Button
            variant={'outline'}
            height={'34px'}
            ml={3}
            width={'100px'}
            colorScheme={'gray'}
            color={'text1'}
            onClick={async () => {
              await copyToClipboard(PATTERNS_IP);
              toast({
                title: 'IP copied to clipboard',
                status: 'success',
                duration: 2000,
                isClosable: true,
              });
            }}
          >
            Copy
          </Button>
        </Flex>
      </FormControl>
      <Divider style={{ borderColor: colors.gray[400] }} my={3} />
    </>
  );
};

export default PatternsIP;
