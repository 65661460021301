import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CloudStorage } from 'types/api';
import { getGraphStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphStorageQueryKey } from 'utils/queryKeys';

function useGraphStorage(
  graphUID: string,
  options: UseQueryOptions<CloudStorage, AxiosError> = {},
) {
  const url = getGraphStorageURL(graphUID);

  return useQuery<CloudStorage, AxiosError>(
    GraphStorageQueryKey(graphUID),
    async () => {
      let res = await Axios.get<CloudStorage>(url!);
      return res?.data;
    },
    {
      enabled: !!graphUID,
      ...options,
    },
  );
}

export default useGraphStorage;
