import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from '@chakra-ui/react';
import { colors } from 'styles/colors';

import { DatabricksCloudStorageConfig } from 'types/api';

import CustomFormControl from './components/CustomFormControl';
import NamespaceBanner from './components/NamespaceBanner';
import PatternsIP from './components/PatternsIP';
import { ExtraConfig, SpecificDatabaseProps } from './DatabaseForm';

type DatabricksConfig = DatabricksCloudStorageConfig & ExtraConfig;

const DatabricksForm = ({
  storageType,
  onSubmit,
  GoBack,
  storage,
  isLoading,
}: SpecificDatabaseProps) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const form = useForm<DatabricksConfig>({
    defaultValues: {
      ...(storage?.config as DatabricksConfig),
      name: storage?.name,
      useSSL: storage?.config ? !!(storage?.config as DatabricksConfig).ssl_config : false,
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const passwordRequired = !storage; // password is required when creating but not when updating

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <VStack gap={2}>
        <CustomFormControl
          label="Integration Name"
          isRequired={true}
          isInvalid={!!errors?.name}
          errorMessage={errors?.name?.message}
        >
          <Input
            placeholder={'e.g. Revenue forecasts'}
            {...register('name', { required: true, maxLength: 32 })}
          />
        </CustomFormControl>

        <Divider style={{ borderColor: colors.gray[400] }} my={3} />

        <CustomFormControl label="Host" isRequired={true} isInvalid={!!errors?.hostname}>
          <Input
            placeholder="*********.cloud.databricks.com"
            {...register('hostname', {
              required: true,
            })}
          />
        </CustomFormControl>
        <CustomFormControl label="HTTP Path" isRequired={true} isInvalid={!!errors?.http_path}>
          <Input
            placeholder="/sql/1.0/warehouses/*****"
            {...register('http_path', {
              required: true,
            })}
          />
        </CustomFormControl>
        <CustomFormControl
          label="Token"
          isRequired={!storage}
          isInvalid={!!errors?.personal_access_token}
        >
          <InputGroup>
            <Input
              type={show ? 'text' : 'password'}
              placeholder="********"
              {...register('personal_access_token', {
                required: passwordRequired,
              })}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.5rem" size="sm" onClick={handleClick}>
                {show ? 'Hide' : 'Show'}
              </Button>
            </InputRightElement>
          </InputGroup>
        </CustomFormControl>
        <CustomFormControl label="Catalog" isRequired={true} isInvalid={!!errors?.catalog_name}>
          <Input
            placeholder="RegionalSales"
            {...register('catalog_name', {
              required: true,
            })}
          />
        </CustomFormControl>

        <NamespaceBanner />

        <PatternsIP />

        <Flex width={'100%'} justifyContent={'flex-end'}>
          {GoBack && (
            <Button
              variant={'outline'}
              borderColor={'#BBBEC7'}
              color={'text2'}
              bg={'white'}
              _hover={{ borderColor: '#60687D' }}
              _active={{ bg: '#F4F4F6' }}
              onClick={() => GoBack()}
              my={'auto'}
              size={'sm'}
              borderRadius={'md'}
              ml={'auto'}
              mr={3}
            >
              Back
            </Button>
          )}
          <Button
            isDisabled={!storageType}
            type="submit"
            size={'sm'}
            borderRadius={'md'}
            isLoading={isLoading}
          >
            {storage ? 'Update' : 'Connect'} Databricks
          </Button>
        </Flex>
      </VStack>
    </form>
  );
};

export default DatabricksForm;
