import React, { ReactNode } from 'react';
import {
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  ResponsiveValue,
} from '@chakra-ui/react';

type CustomFormControlProps = {
  label: string;
  helpText?: ReactNode | string;
  helpTextAlign?: ResponsiveValue<'left' | 'right'>;
  errorMessage?: string;
  children: ReactNode;
} & FormControlProps;

export const CustomFormControl = ({
  label,
  helpText,
  helpTextAlign = 'left',
  errorMessage,
  children,
  ...props
}: CustomFormControlProps) => {
  return (
    <FormControl {...props} textAlign={'right'}>
      <Flex display={{ base: 'block', sm: 'flex' }}>
        <FormLabel minWidth={'150px'} maxWidth={'150px'} my={'auto'}>
          {label}
        </FormLabel>
        {children}
      </Flex>
      {helpText && <FormHelperText textAlign={helpTextAlign}>{helpText}</FormHelperText>}
      {errorMessage && <FormErrorMessage>{errorMessage}</FormErrorMessage>}
    </FormControl>
  );
};
export default CustomFormControl;
