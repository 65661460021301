import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { TablePaginated } from 'types/api';
import { getGraphTablesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphTablesQueryKey } from 'utils/queryKeys';

import { useGraphUID } from './useGraph';

const GRAPH_TABLES_PAGE_SIZE = 1000;

function useGraphReadOnlyTables(
  providedGraphUID?: string,
  pageSize?: number,
  options?: UseQueryOptions<TablePaginated>,
): UseQueryResult<TablePaginated> {
  const foundGraphUID = useGraphUID();
  const graphUID = providedGraphUID || foundGraphUID;
  const url = getGraphTablesURL(graphUID);

  const pageSizeToUse = pageSize || GRAPH_TABLES_PAGE_SIZE;

  return useQuery<TablePaginated>(
    GraphTablesQueryKey(graphUID, pageSizeToUse),
    async () => {
      const res = await Axios.get<TablePaginated>(url, {
        params: { page: 0, page_size: pageSizeToUse },
      });
      return res.data;
    },
    {
      enabled: !!graphUID,
      ...(options as UseQueryOptions<TablePaginated>),
    },
  );
}

export default useGraphReadOnlyTables;
