import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getGraphAvailableTablesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphAvailableTablesQueryKey } from 'utils/queryKeys';

import { useGraphUID } from './useGraph';

type GraphAvailableTables = {
  tables: Record<string, Record<string, string[]>>;
};

function useGraphAvailableTables(
  providedGraphUID?: string,
  options?: UseQueryOptions<GraphAvailableTables>,
) {
  const foundGraphUID = useGraphUID();
  const graphUID = providedGraphUID || foundGraphUID;
  const url = getGraphAvailableTablesURL(graphUID);

  return useQuery<GraphAvailableTables>(
    GraphAvailableTablesQueryKey(graphUID),
    async () => {
      const res = await Axios.get<GraphAvailableTables>(url);
      return res.data;
    },
    {
      enabled: !!graphUID,
      ...options,
    },
  );
}

export default useGraphAvailableTables;
