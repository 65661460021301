import React, { useState } from 'react';
import { Divider, Flex, Link, SimpleGrid } from '@chakra-ui/react';
import { colors } from 'styles/colors';

import DatabaseForm from 'components/DatabaseForm';
import { storageTypes } from 'components/DatabaseForm/DatabaseForm';
import { H2, P2 } from 'components/Typography';
import { CloudStorage, CloudStorageTypes } from 'types/api';

import ConnectionCard from './ConnectionCard';

type Props = {
  onSuccess: (storage: CloudStorage) => void;
  GoBack?: () => void;
};

const ConnectDataSource = ({ onSuccess, GoBack }: Props) => {
  const [datasource, setDatasource] = useState<CloudStorageTypes | undefined>(undefined);

  return (
    <Flex direction="column" gap={2}>
      <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={{ base: 2, sm: 3 }} mb={2}>
        {storageTypes.map((storageType) => (
          <ConnectionCard
            key={storageType.name}
            Icon={storageType.Icon}
            label={storageType.label}
            isSelected={datasource === storageType.name}
            onClick={() => setDatasource(storageType.name)}
          />
        ))}
      </SimpleGrid>

      <P2 mt={2} textAlign={'center'} color={'text3'}>
        Or click{' '}
        <Link
          color="light.action"
          fontWeight={600}
          opacity={0.8}
          onClick={() => {
            const url = window.location.origin + '/members';
            window.open(url, '_blank', 'noopener,noreferrer');
          }}
        >
          here
        </Link>{' '}
        to invite a team member to help you
      </P2>

      {datasource && (
        <>
          <Divider style={{ borderColor: colors.gray[400] }} mt={2} mb={3} />
          <Flex justifyContent={'space-between'} mb={4}>
            <H2 fontWeight={500}>{storageTypes.find((t) => t.name === datasource)?.label}</H2>
          </Flex>
          <DatabaseForm datasource={datasource} onSuccess={onSuccess} GoBack={GoBack} />
        </>
      )}
    </Flex>
  );
};

export default ConnectDataSource;
