import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useOrganizationUID } from 'hooks/useOrganization';
import { CloudStoragePaginated } from 'types/api';
import { getStoragesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useStorages(options?: UseQueryOptions<CloudStoragePaginated>) {
  const organizationUID = useOrganizationUID();

  const url = getStoragesURL(organizationUID);

  return useQuery<CloudStoragePaginated>(
    ['storages', organizationUID],
    async () => {
      const res = await Axios.get<CloudStoragePaginated>(url, {
        params: { page_size: 100, page: 1 },
      });

      return res.data;
    },
    {
      enabled: !!organizationUID,
      ...options,
    },
  );
}

export default useStorages;
