import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useGraphUID } from 'hooks/api/graphs/useGraph';
import { Table } from 'types/api';
import { refreshTableMetadataURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphTablesQueryKey } from 'utils/queryKeys';

function useRefreshTableMetadata(
  storageUID: string,
  options?: UseMutationOptions<Table, AxiosError, string>,
) {
  const queryClient = useQueryClient();
  const graphUID = useGraphUID();
  return useMutation<Table, AxiosError, string>(
    async (tablePath: string): Promise<Table> => {
      const url = refreshTableMetadataURL(storageUID);
      const res = await Axios.put<Table>(url, { table_path: tablePath });
      await queryClient.invalidateQueries(GraphTablesQueryKey(graphUID));
      return res.data;
    },
    { ...options },
  );
}

export default useRefreshTableMetadata;
