import React from 'react';
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Link } from '@chakra-ui/react';

export const NamespaceBanner = ({
  isSnowflake = false,
  onClick = () => {},
}: {
  isSnowflake?: boolean;
  onClick?: () => void;
}) => {
  return (
    <Alert status={'info'} borderRadius={'lg'} mt={3}>
      <AlertIcon mb={2} />
      <Box>
        <AlertTitle fontSize={'14px'}>
          Patterns uses a {isSnowflake ? 'schema' : 'namespace'} (<i>patterns</i>) to store query
          results in your database.
        </AlertTitle>
        {isSnowflake ? (
          <AlertDescription fontSize={'14px'}>
            This requires write access.{' '}
            <a href="#" onClick={onClick}>
              Click here
            </a>{' '}
            for instructions setting up Snowflake for use with Patterns
          </AlertDescription>
        ) : (
          <AlertDescription fontSize={'14px'}>
            This requires write access. We'll create the namespace if it doesn't exist.{' '}
            <Link onClick={() => window.Atlas.chat.openWindow()}>Contact us for help</Link>
          </AlertDescription>
        )}
      </Box>
    </Alert>
  );
};

export default NamespaceBanner;
