import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { FiDatabase, FiRefreshCw } from 'react-icons/fi';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Switch,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { AddCsvModal, UploadedFile } from 'modals/AddCsvModal/AddCsvModal';

import useGraphAvailableTables from 'hooks/api/graphs/useGraphAvailableTables';
import useGraphReadOnlyTables from 'hooks/api/graphs/useGraphReadOnlyTables';
import useGraphStorage from 'hooks/api/graphs/useGraphStorage';
import useManageTables from 'hooks/api/graphs/useManageTables';
import useRefreshStorage from 'hooks/api/storages/useRefreshStorage';
import useUploadCsv from 'hooks/api/storages/useUploadCsv';
import useRefreshTableMetadata from 'hooks/api/tables/useRefreshTableMetadata';
import TableIcon from '/public/icons/node-table-icon.svg';

type Props = {
  Toggle: () => void;
  graphUID: string;
  onTableChanges?: (changes: string[]) => void;
  showFooter?: boolean;
};

function addTables(tablesToAdd: string[], currentTables: string[]): string[] {
  return [...currentTables, ...tablesToAdd.filter((t) => !currentTables.includes(t))];
}

function removeTables(tablesToRemove: string[], currentTables: string[]): string[] {
  return currentTables.filter((t) => !tablesToRemove.includes(t));
}

function ManageTables({ Toggle, graphUID, onTableChanges, showFooter = true }: Props) {
  const toast = useToast();

  const { mutate: addCSV, isLoading: isLoadingCsv } = useUploadCsv({
    onError: (error) => {
      toast({
        status: 'error',
        title: 'Error uploading CSV to database',
        description: error.message,
        isClosable: true,
        // duration: 10000,
      });
    },
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const onCsvClose = (uploadedFile?: UploadedFile) => {
    setModalIsOpen(false);
    if (uploadedFile) {
      addCSV(uploadedFile);
    }
  };

  const [selectedTables, setSelectedTables] = useState<string[]>([]);

  const { data: graphTables, isLoading: isLoadingGraphTables } = useGraphReadOnlyTables(graphUID);

  const { data: graphAvailableTables, isLoading: isLoadingAvailableTables } =
    useGraphAvailableTables(graphUID);

  const { data: storage, isLoading, refetch } = useGraphStorage(graphUID);

  useEffect(() => {
    if (!storage?.writable_namespace || graphTables === undefined) {
      return;
    }
    if (graphTables?.results && graphTables.results.length > 0) {
      setSelectedTables(
        graphTables.results.map((t) => {
          return `${t.database || storage.default_database}.${t.namespace}.${t.name}`;
        }),
      );
    } else {
      setSelectedTables([]);
    }
  }, [graphTables, storage?.default_database, storage?.writable_namespace]);

  useEffect(() => {
    onTableChanges && onTableChanges(selectedTables);
  }, [selectedTables, onTableChanges]);

  const { mutate: updateGraphTables, isLoading: isUpdatingTables } = useManageTables({
    onSuccess: () => {
      refetch();
      Toggle();
    },
  });

  const [reduceMotion, setReduceMotion] = useState(true);

  const { mutate: refreshDatabase, isLoading: isRefreshingDatabase } = useRefreshStorage(graphUID);

  const [refreshingTable, setRefreshingTable] = useState<string | null>(null);

  const { mutate: refreshTableMetadata, isLoading: isRefreshingTableMetadata } =
    useRefreshTableMetadata(graphUID, {
      onMutate: (table) => setRefreshingTable(table),
      onSettled: () => setRefreshingTable(null),
    });

  const loading = useMemo(() => {
    return isLoading || !storage || isLoadingGraphTables || isLoadingAvailableTables;
  }, [isLoading, isLoadingAvailableTables, isLoadingGraphTables, storage]);

  useEffect(() => {
    if (!loading) {
      // need to add a delay before enabling motion animations for accordion
      const t = setTimeout(() => setReduceMotion(false), 500);
      return () => clearTimeout(t);
    }
  }, [loading]);

  if (loading) {
    return (
      <Center>
        <Spinner speed={'1.5s'} h={8} w={8} />
      </Center>
    );
  }
  return (
    <Box maxWidth={'600px'} mx={-2}>
      {Object.entries(graphAvailableTables?.tables || {}).map(([databaseName, schemas]) => {
        const schemaNames = Object.keys(schemas || {});
        return (
          <Accordion
            allowToggle
            defaultIndex={0}
            reduceMotion={reduceMotion}
            key={databaseName}
            mb={2}
          >
            <AccordionItem borderColor={'transparent'}>
              <AccordionButton
                as="span"
                flex="1"
                textAlign="left"
                width={'100%'}
                borderColor={'transparent'}
                fontWeight={'bold'}
                alignItems={'center'}
                fontSize={'lg'}
                px={3}
              >
                <Icon as={FiDatabase} width={'16px'} height={'16px'} mr={2} />
                <Text>{databaseName}</Text>

                <Text fontWeight={500} color={'text3'} fontSize={'12px'} ml={2} mt={1}>
                  {schemaNames.length} available schema
                  {schemaNames.length > 1 && 's'}
                </Text>
                <Tooltip label={'Refresh Database'}>
                  <IconButton
                    mx={2}
                    icon={<FiRefreshCw />}
                    aria-label="storage-refresh-schema"
                    variant="ghost"
                    colorScheme="gray"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      refreshDatabase(storage!.uid);
                    }}
                    isLoading={isRefreshingDatabase}
                  />
                </Tooltip>

                <AccordionIcon ml={'auto'} />
              </AccordionButton>

              <AccordionPanel w="100%" mt={-2}>
                {Object.entries(schemas).map(([schema, tables]) => {
                  const tablesAllAdded =
                    tables.length > 0 && tables.every((table) => selectedTables.includes(table));
                  return (
                    <Flex key={schema} flexDir={'column'}>
                      <Flex
                        // mb={1}
                        alignItems={'center'}
                        _hover={{ bg: 'gray.200' }}
                        pl={2}
                        pr={1}
                        ml={-2}
                        mt={2}
                      >
                        <IconWrapper>
                          <Icon as={TableIcon} w="16px" height="16px" />
                        </IconWrapper>
                        <Text
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          fontSize="14px"
                          color="text1"
                          fontWeight={'bold'}
                          mb={0}
                          mt={'1px'}
                          ml={1}
                        >
                          {schema}
                          {databaseName === storage?.default_database &&
                          schema === storage?.writable_namespace
                            ? ' (CSV uploaded tables)'
                            : ''}
                        </Text>
                        {tables && (
                          <Text
                            fontWeight={500}
                            color={'text3'}
                            fontSize={'12px'}
                            ml={2}
                            mt={'2px'}
                          >
                            {tables.length} table{tables.length > 1 && 's'}
                          </Text>
                        )}
                        <Flex mr={1} ml={'auto'}>
                          <Text
                            fontWeight={500}
                            color={'text2'}
                            fontSize={'12px'}
                            mr={2}
                            mt={'2px'}
                          >
                            Select all
                          </Text>
                          <Flex alignItems="center" width={'34px'}>
                            <Switch
                              isChecked={tablesAllAdded}
                              isDisabled={tables.length === 0}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  setSelectedTables(addTables(tables, selectedTables));
                                } else {
                                  setSelectedTables(removeTables(tables, selectedTables));
                                }
                              }}
                            />
                          </Flex>
                        </Flex>
                      </Flex>

                      {tables
                        .sort((a, b) => a.localeCompare(b))
                        .map((table) => {
                          const isAdded = selectedTables.includes(table);
                          const tableParts = table.split('.');
                          const tableName = tableParts[tableParts.length - 1];
                          return (
                            <Flex
                              _hover={{ bg: 'gray.200' }}
                              overflow="hidden"
                              whiteSpace="nowrap"
                              width="100%"
                              // bg={isAdded ? 'gray.200' : undefined}
                              alignItems={'center'}
                              cursor={'default'}
                              px={4}
                              height={'24px'}
                              key={table}
                              role="group"
                            >
                              {/*<IconWrapper>*/}
                              {/*  <TableIcon />*/}
                              {/*</IconWrapper>*/}
                              <Text
                                fontWeight={isAdded ? 600 : 400}
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                fontSize="14px"
                                color="text1"
                              >
                                {tableName}
                              </Text>
                              <Tooltip label={'Refresh Table'}>
                                <IconButton
                                  mx={2}
                                  icon={<FiRefreshCw />}
                                  aria-label="storage-refresh-schema"
                                  variant="ghost"
                                  colorScheme="gray"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    refreshTableMetadata(table);
                                  }}
                                  isLoading={isRefreshingTableMetadata && refreshingTable === table}
                                  isDisabled={
                                    isRefreshingTableMetadata && refreshingTable !== table
                                  }
                                  height={'20px'}
                                  display={'none'}
                                  _groupHover={{ display: isAdded ? 'flex' : 'none' }}
                                />
                              </Tooltip>
                              <Flex ml={'auto'} width={'34px'}>
                                <Flex alignItems="center">
                                  <Switch
                                    isChecked={isAdded}
                                    onChange={() => {
                                      if (isAdded) {
                                        setSelectedTables(removeTables([table], selectedTables));
                                      } else {
                                        setSelectedTables(addTables([table], selectedTables));
                                      }
                                    }}
                                  />
                                </Flex>
                              </Flex>
                            </Flex>
                          );
                        })}
                    </Flex>
                  );
                })}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        );
      })}

      {showFooter && (
        <Flex
          width={'100%'}
          position={'absolute'}
          bottom={'10px'}
          right={0}
          px={5}
          background={'white'}
          pt={'10px'}
          borderTop={'1px'}
          borderTopColor={'border2'}
        >
          <Button
            variant="outline"
            size="sm"
            disabled={isUpdatingTables || isLoading}
            onClick={() => setModalIsOpen(true)}
            borderColor={'#BBBEC7'}
            color={'text2'}
            bg={'white'}
            borderRadius={'md'}
            _hover={{ borderColor: '#60687D' }}
            _active={{ bg: '#F4F4F6' }}
            isLoading={isLoadingCsv}
          >
            Import CSV
          </Button>
          {modalIsOpen && <AddCsvModal onClose={onCsvClose} showShareChat={false} />}

          <Button
            ml={'auto'}
            variant="outline"
            size="sm"
            onClick={Toggle}
            isDisabled={isUpdatingTables || isLoading}
            borderRadius={'md'}
            borderColor={'#BBBEC7'}
            color={'text2'}
            bg={'white'}
            _hover={{ borderColor: '#60687D' }}
            _active={{ bg: '#F4F4F6' }}
          >
            Cancel
          </Button>

          <Button
            ml={3}
            size="sm"
            onClick={() => {
              updateGraphTables({ graphUID, tables: selectedTables });
            }}
            isLoading={isUpdatingTables || isLoading}
            borderRadius={'md'}
          >
            Apply Changes
          </Button>
        </Flex>
      )}
    </Box>
  );
}

const IconWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      {children}
    </Flex>
  );
};

export default ManageTables;
