import { IconType } from 'react-icons';
import { Flex } from '@chakra-ui/react';

type ConnectionCardProps = {
  label: string;
  isSelected: boolean;
  Icon?: IconType;
  onClick: () => void;
};

const ConnectionCard = ({ label, isSelected, Icon, onClick }: ConnectionCardProps) => {
  return (
    <Flex
      flex={1}
      borderRadius={5}
      justify="center"
      align="center"
      px={1}
      py={1}
      borderWidth={'1px'}
      borderColor={isSelected ? 'gray.600' : 'gray.400'}
      _hover={{
        borderColor: isSelected ? 'gray.700' : 'gray.600',
      }}
      _active={{
        bg: 'gray.400',
      }}
      bg={isSelected ? '#49526A' : 'white'}
      cursor="pointer"
      gap={2}
      onClick={onClick}
    >
      {Icon && (
        <Flex>
          <Icon color={isSelected ? 'white' : '#868E96'} size={30} />
        </Flex>
      )}
      <Flex color={isSelected ? 'white' : 'text1'} fontWeight={isSelected ? '600' : '400'}>
        {label}
      </Flex>
    </Flex>
  );
};

export default ConnectionCard;
