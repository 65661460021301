import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { Switch, Textarea, VStack } from '@chakra-ui/react';
import { ExtraConfig } from 'components/DatabaseForm/DatabaseForm';

import { CloudStorage, CloudStorageConfig } from 'types/api';

import CustomFormControl from './CustomFormControl';

const SslFormElement = ({
  storage,
  form,
}: {
  storage?: CloudStorage;
  form: UseFormReturn<CloudStorageConfig & ExtraConfig>;
}) => {
  const {
    register,
    control,
    watch,
    formState: { errors },
  } = form;

  const sslEnabled = watch('useSSL');

  return (
    <>
      <CustomFormControl label="Use SSL?" isInvalid={!!errors?.useSSL} display={'flex'} mt={2}>
        <Controller
          name="useSSL"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Switch isChecked={value} onChange={(event) => onChange(event.currentTarget.checked)} />
          )}
        />
      </CustomFormControl>

      {sslEnabled && (
        <VStack ml={5} width={'100%'} gap={2}>
          <CustomFormControl
            label="SSL Server Certificate"
            isRequired={true}
            isInvalid={!!errors?.ssl_config?.ca}
            errorMessage={errors?.ssl_config?.ca?.message}
          >
            <Textarea {...register('ssl_config.ca')} fontSize={'sm'} />
          </CustomFormControl>

          <CustomFormControl
            label="SSL Client Certificate"
            isRequired={true}
            isInvalid={!!errors?.ssl_config?.cert}
            errorMessage={errors?.ssl_config?.cert?.message}
          >
            <Textarea {...register('ssl_config.cert')} fontSize={'sm'} />
          </CustomFormControl>

          <CustomFormControl
            label="SSL Client Key"
            isRequired={!storage}
            isInvalid={!!errors?.ssl_config?.key}
            errorMessage={errors?.ssl_config?.key?.message}
            helpText={
              !!storage &&
              'Client key is readonly. If you need to change it, enter your new client key.'
            }
            helpTextAlign={'right'}
          >
            <Textarea {...register('ssl_config.key')} fontSize={'sm'} />
          </CustomFormControl>
        </VStack>
      )}
    </>
  );
};

export default SslFormElement;
