export const scriptContent = (
  username: string,
  password: string,
  database: string,
  warehouse: string,
  role: string,
) => `
Use the following script to create a user (${username}) and role (${role}) in your Snowflake for Patterns.

### What permissions am I giving Patterns?
- Read access to tables in your database and schema
- Access to your Snowflake warehouse
- Read/Write access to the patterns schema in your database (the script creates this schema for you)

\`\`\`sql
-- Create role
CREATE ROLE IF NOT EXISTS ${role};

-- Create user
CREATE USER IF NOT EXISTS ${username}
  PASSWORD = '${password}'
  LOGIN_NAME = ${username} 
  COMMENT = 'User for Patterns data analysis tool';

-- Create patterns schema in database
CREATE SCHEMA IF NOT EXISTS ${database}.patterns;

-- Give role access to database
GRANT USAGE ON DATABASE ${database} TO ROLE ${role};

-- Give role access to all schemas in the database
GRANT USAGE ON ALL SCHEMAS IN DATABASE ${database} TO ROLE ${role};

-- Give role read access to all tables and future tables in all schemas
GRANT SELECT ON ALL TABLES IN DATABASE ${database} TO ROLE ${role};
GRANT SELECT ON FUTURE TABLES IN DATABASE ${database} TO ROLE ${role};

-- Give role permission to create tables for patterns schema 
GRANT CREATE TABLE ON SCHEMA ${database}.patterns TO ROLE ${role};

-- Give role read/write access to all tables and future tables for patterns schema
GRANT SELECT, INSERT, UPDATE, DELETE ON ALL TABLES IN SCHEMA ${database}.patterns TO ROLE ${role};
GRANT SELECT, INSERT, UPDATE, DELETE ON FUTURE TABLES IN SCHEMA ${database}.patterns TO ROLE ${role};

-- Give role access to warehouse
GRANT USAGE ON WAREHOUSE ${warehouse} TO ROLE ${role};

-- Assign role to the user
GRANT ROLE ${role} TO USER ${username};
\`\`\`
  `;
