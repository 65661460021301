import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Markdown from 'components/Markdown';
import Image from 'next/image';
import { colors } from 'styles/colors';

import { SnowflakeCloudStorageConfig } from 'types/api';

import CustomFormControl from './components/CustomFormControl';
import InputPassword from './components/InputPassword';
import NamespaceBanner from './components/NamespaceBanner';
import PatternsIP from './components/PatternsIP';
import { scriptContent } from './components/SnowFlakeScript';
import SslFormElement from './components/SslFormElement';
import { ExtraConfig, SpecificDatabaseProps } from './DatabaseForm';

type SnowflakeConfig = SnowflakeCloudStorageConfig & ExtraConfig;

const SnowflakeForm = ({
  storageType,
  onSubmit,
  GoBack,
  storage,
  isLoading,
}: SpecificDatabaseProps) => {
  const form = useForm<SnowflakeConfig>({
    defaultValues: {
      ...(storage?.config as SnowflakeConfig),
      name: storage?.name,
      useSSL: storage?.config ? !!(storage?.config as SnowflakeConfig).ssl_config : false,
    },
  });
  const { isOpen: isImageOpen, onOpen: onImageOpen, onClose: onImageClose } = useDisclosure();
  const { isOpen: isScriptOpen, onOpen: onScriptOpen, onClose: onScriptClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = form;

  const handleButtonClick = (e: any) => {
    e.preventDefault();
  };

  const handleModalConfirm = () => {
    handleSubmit(onSubmit)();
  };

  const watchedValues = watch();

  const passwordRequired = !storage; // password is required when creating but not when updating

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <VStack gap={2}>
          <CustomFormControl
            label={'Name'}
            isRequired={true}
            isInvalid={!!errors?.name}
            errorMessage={errors?.name?.message}
          >
            <Input {...register('name', { required: true, maxLength: 32 })} />
          </CustomFormControl>

          <Divider style={{ borderColor: colors.gray[400] }} my={3} />

          <CustomFormControl
            label="Account Identifier"
            helpText={
              <Box mb={2}>
                Where to find this in{' '}
                <a href="#" onClick={onImageOpen}>
                  Snowflake
                </a>
              </Box>
            }
            isRequired={true}
            isInvalid={!!errors?.account_identifier}
          >
            <Input
              placeholder="orgname-account_name"
              {...register('account_identifier', {
                required: true,
                setValueAs: (value) => value.replace('.', '-'),
              })}
            />
          </CustomFormControl>

          <CustomFormControl label="Username" isRequired={true} isInvalid={!!errors?.user}>
            <Input {...register('user', { required: true })} />
          </CustomFormControl>

          <CustomFormControl
            label="Password"
            isRequired={!storage}
            isInvalid={!!errors?.password}
            helpText={
              !!storage &&
              'Password is readonly. If you need to change it, enter your new password.'
            }
            helpTextAlign={'right'}
          >
            <InputPassword {...register('password', { required: passwordRequired })} />
          </CustomFormControl>

          <CustomFormControl label="Database" isRequired={true} isInvalid={!!errors?.database}>
            <Input {...register('database', { required: true })} />
          </CustomFormControl>

          <CustomFormControl label="Warehouse" isRequired={true} isInvalid={!!errors?.warehouse}>
            <Input {...register('warehouse', { required: true })} />
          </CustomFormControl>

          <CustomFormControl label="Role" isRequired={true} isInvalid={!!errors?.role}>
            <Input placeholder="accountadmin" {...register('role', { required: true })} />
          </CustomFormControl>

          {/* @ts-ignore */}
          <SslFormElement storage={storage} form={form} />

          <NamespaceBanner isSnowflake={true} onClick={onScriptOpen} />

          <PatternsIP />

          <Flex width={'100%'} justifyContent={'flex-end'}>
            {GoBack && (
              <Button
                variant={'outline'}
                borderColor={'#BBBEC7'}
                color={'text2'}
                bg={'white'}
                _hover={{ borderColor: '#60687D' }}
                _active={{ bg: '#F4F4F6' }}
                onClick={() => GoBack()}
                my={'auto'}
                size={'sm'}
                borderRadius={'md'}
                ml={'auto'}
                mr={3}
              >
                Back
              </Button>
            )}
            <Button
              isDisabled={!storageType}
              onClick={(e) => {
                handleButtonClick(e);
                onScriptOpen();
              }}
              size={'sm'}
              borderRadius={'md'}
              isLoading={isLoading}
            >
              {storage ? 'Update' : 'Connect'} Snowflake
            </Button>
          </Flex>
        </VStack>
      </form>

      <Modal size={'full'} isOpen={isImageOpen} onClose={onImageClose}>
        <ModalOverlay />
        <ModalContent bg={'white'}>
          <ModalHeader>Where to find your snowflake account id</ModalHeader>
          <ModalCloseButton />
          <ModalBody mx={'auto'}>
            <Image
              src="/images/connections/snowflake_account_id.png"
              width={1600}
              height={1600}
              alt="Where to find your account id on Snowflake"
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal size={'4xl'} isOpen={isScriptOpen} onClose={onScriptClose} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent bg={'white'} maxH="calc(100vh - 150px)">
          <ModalHeader>Snowflake Permission Instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody px={12}>
            <Markdown>
              {scriptContent(
                watchedValues.user!,
                watchedValues.password!,
                watchedValues.database,
                watchedValues.warehouse,
                watchedValues.role!,
              )}
            </Markdown>
          </ModalBody>
          <ModalFooter position="sticky" bottom="0" bg="white" zIndex={1}>
            <Button
              onClick={() => {
                onScriptClose();
                handleModalConfirm();
              }}
            >
              I've executed the script
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SnowflakeForm;
