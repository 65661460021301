import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { Graph } from 'types/api';
import { updateGraphTablesURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphTablesQueryKey } from 'utils/queryKeys';

export type AddExternalTablesRequest = {
  graphUID: string;
  tables: string[];
};

function useManageTables(
  options?: UseMutationOptions<Graph, AxiosError, AddExternalTablesRequest>,
) {
  const queryClient = useQueryClient();
  return useMutation<Graph, AxiosError, AddExternalTablesRequest>(
    async (data: AddExternalTablesRequest): Promise<Graph> => {
      const url = updateGraphTablesURL(data.graphUID);
      const res = await Axios.post<Graph>(url, { tables: data.tables });
      await queryClient.invalidateQueries(GraphTablesQueryKey(data.graphUID));
      return res.data;
    },
    options,
  );
}

export default useManageTables;
