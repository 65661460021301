import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { useOrganizationUID } from 'hooks/useOrganization';
import { GraphPaginated } from 'types/api';
import { getGraphsURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

function useGraphs(options?: UseQueryOptions<GraphPaginated>) {
  const organizationUID = useOrganizationUID();
  const url = getGraphsURL(organizationUID);

  return useQuery<GraphPaginated>(
    ['organizations', organizationUID, 'graphs'],
    async () => {
      const res = await Axios.get<GraphPaginated>(url);
      return res.data;
    },
    {
      enabled: !!organizationUID,
      ...options,
    },
  );
}

export default useGraphs;
