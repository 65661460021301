import { useToast } from '@chakra-ui/react';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useOrganizationUID } from 'hooks/useOrganization';
import { CloudStorage, CloudStorageConfig } from 'types/api';
import { addStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StoragesQueryKey } from 'utils/queryKeys';

export type AddStorage = {
  name: string;
  storage_config: CloudStorageConfig;
};

function useCreateStorage(options: UseMutationOptions<CloudStorage, AxiosError, AddStorage> = {}) {
  const queryClient = useQueryClient();
  const organizationUid = useOrganizationUID();
  const url = addStorageURL(organizationUid);
  const toast = useToast();

  return useMutation<CloudStorage, AxiosError, AddStorage>(async (data: AddStorage) => {
    data.name = data.name.replace(/[^a-zA-Z0-9_]+/g, '_');
    const res = await Axios.post<CloudStorage>(url, data);
    queryClient.invalidateQueries(StoragesQueryKey());
    toast({
      status: 'success',
      title: 'Successfully added database',
      isClosable: true,
      duration: 3000,
    });
    return res.data;
  }, options);
}

export default useCreateStorage;
