import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UploadedFile } from 'modals/AddCsvModal/AddCsvModal';

import { useGraphUID } from 'hooks/api/graphs/useGraph';
import { Graph } from 'types/api';
import { addTableFromCsvURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { GraphAvailableTablesQueryKey, GraphTablesQueryKey } from 'utils/queryKeys';

function useUploadCsv(options?: UseMutationOptions<Graph, AxiosError, UploadedFile>) {
  const queryClient = useQueryClient();

  const graphUID = useGraphUID();
  return useMutation<Graph, AxiosError, UploadedFile>(async ({ file, name }): Promise<Graph> => {
    const url = addTableFromCsvURL(graphUID);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', name);

    const res = await Axios.post<Graph>(url, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    await queryClient.invalidateQueries(GraphTablesQueryKey(graphUID));
    await queryClient.invalidateQueries(GraphAvailableTablesQueryKey(graphUID));

    return res.data;
  }, options);
}

export default useUploadCsv;
