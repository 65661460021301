import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CloudStorage, CloudStorageConfig } from 'types/api';
import { updateStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { StorageQueryKey, StoragesQueryKey } from 'utils/queryKeys';

export type StorageUpdateProps = {
  storageUid: string;
  update: CloudStorageConfig;
};

function useUpdateStorage(
  options: UseMutationOptions<CloudStorage, AxiosError, StorageUpdateProps> = {},
) {
  const queryClient = useQueryClient();

  return useMutation<CloudStorage, AxiosError, StorageUpdateProps>(
    async (props: StorageUpdateProps) => {
      const url = updateStorageURL(props.storageUid);
      if (!url) throw new Error('URL is not undefined');

      const res = await Axios.put<CloudStorage>(url, {
        storage_config: props.update,
      });
      queryClient.invalidateQueries(StorageQueryKey(props.storageUid));
      queryClient.invalidateQueries(StoragesQueryKey());
      return res.data;
    },
    options,
  );
}

export default useUpdateStorage;
