import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Divider, Flex, Input, ListItem, UnorderedList, VStack } from '@chakra-ui/react';
import { json } from '@codemirror/lang-json';
import { githubLight } from '@uiw/codemirror-theme-github';
import CodeMirror from '@uiw/react-codemirror';
import { colors } from 'styles/colors';

import { P2 } from 'components/Typography';
import { BigQueryCloudStorageConfig } from 'types/api';

import CustomFormControl from './components/CustomFormControl';
import NamespaceBanner from './components/NamespaceBanner';
import PatternsIP from './components/PatternsIP';
import SslFormElement from './components/SslFormElement';
import { ExtraConfig, SpecificDatabaseProps } from './DatabaseForm';

type BigQueryConfig = BigQueryCloudStorageConfig & ExtraConfig;

const BigQueryForm = ({
  storageType,
  onSubmit,
  GoBack,
  storage,
  isLoading,
}: SpecificDatabaseProps) => {
  const form = useForm<BigQueryConfig>({
    defaultValues: {
      ...(storage?.config as BigQueryConfig),
      name: storage?.name,
      useSSL: storage?.config ? !!(storage?.config as BigQueryConfig).ssl_config : false,
    },
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = form;

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <VStack gap={2}>
        <CustomFormControl
          label="Name"
          isRequired={true}
          isInvalid={!!errors?.name}
          errorMessage={errors?.name?.message}
        >
          <Input {...register('name', { required: true, maxLength: 32 })} />
        </CustomFormControl>

        <Divider style={{ borderColor: colors.gray[400] }} my={3} />

        <CustomFormControl
          label="Project Name"
          isRequired={true}
          isInvalid={!!errors?.project_name}
        >
          <Input
            placeholder="jocelyn-doe-983429"
            {...register('project_name', {
              required: true,
            })}
          />
        </CustomFormControl>

        <CustomFormControl
          label="Dataset Name"
          isRequired={true}
          isInvalid={!!errors?.dataset_name}
        >
          <Input
            placeholder="dataset"
            {...register('dataset_name', {
              required: true,
            })}
          />
        </CustomFormControl>

        <CustomFormControl
          label="Service Account Private Key (.json)"
          isRequired={!storage}
          isInvalid={!!errors?.private_key_json}
          helpText={
            <Flex flexDirection="column" gap={2}>
              <P2 textAlign={'right'}>
                Private key is readonly. If you need to change it, enter your new private key.
              </P2>
              <P2>
                Paste the contents of your Service Account Private Key here. A Service Account
                Private Key is a .json file created in the Google Cloud Console which provides
                access to BigQuery. It can be created with{' '}
                <a
                  href="https://cloud.google.com/iam/docs/creating-managing-service-account-keys#iam-service-account-keys-create-console"
                  target="_blank"
                >
                  these instructions
                </a>
                .
              </P2>
              <P2>
                In addition, the service account must be granted the following roles:
                <UnorderedList>
                  <ListItem>BigQuery Data Owner</ListItem>
                  <ListItem>BigQuery Job User</ListItem>
                  <ListItem>BigQuery Read Session Use</ListItem>
                </UnorderedList>
              </P2>
            </Flex>
          }
        >
          <Flex
            _focusWithin={{ borderColor: '#5c7cfa', boxShadow: '0 0 0 1px #5c7cfa' }}
            borderRadius={'4px'}
            border="1px solid #e9ecef"
            width={{ base: '100%', sm: 'calc(100% - 162px)' }}
            height={'100%'}
            p={'1px'}
          >
            <Controller
              name="private_key_json"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CodeMirror
                  value={value || ''}
                  theme={githubLight}
                  extensions={[json()]}
                  autoFocus={true}
                  onChange={onChange}
                  height={'80px'}
                  width={'100%'}
                />
              )}
            />
          </Flex>
        </CustomFormControl>

        {/* @ts-ignore */}
        <SslFormElement storage={storage} form={form} />

        <NamespaceBanner />

        <PatternsIP />

        <Flex width={'100%'} justifyContent={'flex-end'}>
          {GoBack && (
            <Button
              variant={'outline'}
              borderColor={'#BBBEC7'}
              color={'text2'}
              bg={'white'}
              _hover={{ borderColor: '#60687D' }}
              _active={{ bg: '#F4F4F6' }}
              onClick={() => GoBack()}
              my={'auto'}
              size={'sm'}
              borderRadius={'md'}
              ml={'auto'}
              mr={3}
            >
              Back
            </Button>
          )}
          <Button
            isDisabled={!storageType}
            type="submit"
            size={'sm'}
            borderRadius={'md'}
            isLoading={isLoading}
          >
            {storage ? 'Update' : 'Connect'} BigQuery
          </Button>
        </Flex>
      </VStack>
    </form>
  );
};

export default BigQueryForm;
