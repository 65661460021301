import React, { useCallback } from 'react';
import { IconType } from 'react-icons';
import { DiGoogleCloudPlatform, DiMsqlServer, DiMysql, DiPostgresql } from 'react-icons/di';
import { SiAmazonredshift } from 'react-icons/si';
import { TbBrandSnowflake } from 'react-icons/tb';
import { Alert, AlertDescription, AlertIcon, Flex } from '@chakra-ui/react';
import { IconBrandDatabricks } from '@tabler/icons-react';

import useCreateStorage from 'hooks/api/storages/useCreateStorage';
import useUpdateStorage from 'hooks/api/storages/useUpdateStorage';
import { CloudStorage, CloudStorageConfig, CloudStorageTypes } from 'types/api';

import BigQueryForm from './BigQueryForm';
import ErrorDisplay from './components/ErrorDisplay';
import DatabricksForm from './DatabricksForm';
import SnowflakeForm from './SnowflakeForm';
import SqlForm from './SqlForm';

export type StorageType = {
  name: CloudStorageTypes;
  label: string;
  Icon: IconType;
};

export const PostgresStorageType: StorageType = {
  name: 'postgres',
  label: 'Postgres',
  Icon: DiPostgresql,
};

export const RedshiftStorageType: StorageType = {
  name: 'redshift',
  label: 'Redshift',
  Icon: SiAmazonredshift,
};

export const MySqlStorageType: StorageType = {
  name: 'mysql',
  label: 'MySQL',
  Icon: DiMysql,
};

export const BigQueryStorageType: StorageType = {
  name: 'bigquery',
  label: 'BigQuery',
  Icon: DiGoogleCloudPlatform,
};

export const SnowflakeStorageType: StorageType = {
  name: 'snowflake',
  label: 'Snowflake',
  Icon: TbBrandSnowflake,
};

export const DatabricksStorageType: StorageType = {
  name: 'databricks',
  label: 'Databricks',
  // @ts-ignore
  Icon: IconBrandDatabricks,
};

export const MsSqlStorageType: StorageType = {
  name: 'mssql',
  label: 'Microsoft SQL Server',
  Icon: DiMsqlServer,
};

export const storageTypes = [
  PostgresStorageType,
  MySqlStorageType,
  RedshiftStorageType,
  BigQueryStorageType,
  SnowflakeStorageType,
  MsSqlStorageType,
  DatabricksStorageType,
];

export type ExtraConfig = {
  name: string;
  useSSH: boolean;
  useSSL: boolean;
};

type Props = {
  datasource: CloudStorageTypes;
  storage?: CloudStorage;
  onSuccess: (s: CloudStorage) => void;
  GoBack?: () => void;
};

export type SpecificDatabaseProps = {
  storageType?: StorageType;
  onSubmit: (data: CloudStorageConfig & ExtraConfig) => void;
  GoBack?: Function;
  storage?: CloudStorage;
  isLoading: boolean;
};

function DatabaseForm({ onSuccess, GoBack, datasource, storage }: Props) {
  const {
    mutate: createStorage,
    error: linkError,
    isLoading: linking,
  } = useCreateStorage({
    onSuccess: onSuccess,
  });

  const {
    mutate: updateStorage,
    error: updateError,
    isLoading: updating,
  } = useUpdateStorage({ onSuccess: onSuccess });

  const onSubmit = useCallback(
    (data: CloudStorageConfig & ExtraConfig) => {
      const { name, useSSH, useSSL, ...config } = data;
      if (!useSSL) {
        config.ssl_config = undefined;
      }
      config.type = datasource;
      if (storage) {
        updateStorage({ storageUid: storage.uid, update: config });
      } else {
        createStorage({ name: name, storage_config: config });
      }
    },
    [datasource, storage, updateStorage, createStorage],
  );

  return (
    <Flex flexDir={'column'}>
      {datasource == MySqlStorageType.name && (
        <SqlForm
          storageType={MySqlStorageType}
          onSubmit={onSubmit}
          GoBack={GoBack}
          storage={storage}
          isLoading={linking || updating}
        />
      )}

      {datasource === PostgresStorageType.name && (
        <SqlForm
          storageType={PostgresStorageType}
          onSubmit={onSubmit}
          GoBack={GoBack}
          storage={storage}
          isLoading={linking || updating}
        />
      )}

      {datasource === RedshiftStorageType.name && (
        <SqlForm
          storageType={RedshiftStorageType}
          onSubmit={onSubmit}
          GoBack={GoBack}
          storage={storage}
          isLoading={linking || updating}
        />
      )}
      {datasource === MsSqlStorageType.name && (
        <SqlForm
          storageType={MsSqlStorageType}
          onSubmit={onSubmit}
          GoBack={GoBack}
          storage={storage}
          isLoading={linking || updating}
        />
      )}
      {datasource === DatabricksStorageType.name && (
        <DatabricksForm
          storageType={MsSqlStorageType}
          onSubmit={onSubmit}
          GoBack={GoBack}
          storage={storage}
          isLoading={linking || updating}
        />
      )}
      {datasource === BigQueryStorageType.name && (
        <BigQueryForm
          storageType={BigQueryStorageType}
          onSubmit={onSubmit}
          GoBack={GoBack}
          storage={storage}
          isLoading={linking || updating}
        />
      )}
      {datasource === SnowflakeStorageType.name && (
        <SnowflakeForm
          storageType={SnowflakeStorageType}
          onSubmit={onSubmit}
          GoBack={GoBack}
          storage={storage}
          isLoading={linking || updating}
        />
      )}
      {linkError && <ErrorDisplay error={linkError} />}
      {!!updateError?.message && (
        <Alert status="error">
          <AlertIcon />
          <AlertDescription>{updateError?.message}</AlertDescription>
        </Alert>
      )}
    </Flex>
  );
}

export default DatabaseForm;
