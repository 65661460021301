import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CloudStorage } from 'types/api';
import { refreshStorageURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import {
  GraphAvailableTablesQueryKey,
  GraphStorageQueryKey,
  GraphTablesQueryKey,
  StorageQueryKey,
} from 'utils/queryKeys';

function useRefreshStorage(graphUID?: string) {
  const queryClient = useQueryClient();

  return useMutation<any, AxiosError, string>(async (storageUID: string) => {
    const res = await Axios.get<CloudStorage>(refreshStorageURL(storageUID));

    if (graphUID) {
      await queryClient.invalidateQueries(GraphStorageQueryKey(graphUID));
      await queryClient.invalidateQueries(GraphTablesQueryKey(graphUID));
      await queryClient.invalidateQueries(GraphAvailableTablesQueryKey(graphUID));
    }

    await queryClient.invalidateQueries(StorageQueryKey(storageUID));

    return res.data;
  });
}

export default useRefreshStorage;
