import { forwardRef, useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  useBoolean,
} from '@chakra-ui/react';

const InputPassword = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [show, { toggle }] = useBoolean(false);

  const [passwordLength, setPasswordLength] = useState(0);

  return (
    <InputGroup size="md" alignItems="center" justifyContent="center">
      <Input
        ref={ref}
        type={show ? 'text' : 'password'}
        {...props}
        onChange={(e) => setPasswordLength(e.target.value.length)}
      />
      {passwordLength > 0 && (
        <InputRightElement width="4.5rem" alignItems="center" justifyContent="center">
          <Button
            variant="outline"
            size="sm"
            onClick={() => toggle()}
            borderColor={'#BBBEC7'}
            color={'text2'}
            bg={'white'}
            borderRadius={'md'}
            _hover={{ borderColor: '#60687D' }}
            _active={{ bg: '#F4F4F6' }}
          >
            {show ? 'Hide' : 'Show'}
          </Button>
        </InputRightElement>
      )}
    </InputGroup>
  );
});

InputPassword.displayName = 'InputPassword';

export default InputPassword;
