import { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Collapse,
  Flex,
  Link,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';

import { P2 } from 'components/Typography';

const ErrorDisplay = ({ error }: { error: AxiosError }) => {
  // if we get a 424 then we couldn't connect to the database, if we get a 429 then we couldn't create a schema
  const errorCode = error?.response?.status || 424;

  const errorTitle =
    errorCode === 422
      ? 'There was an error writing a schema to your database'
      : 'There was a problem connecting to your database.';
  const errorDescription =
    errorCode === 422 ? (
      <>
        Verify you have write access to your database, or{' '}
        <Link onClick={() => window.Atlas.chat.openWindow()}>contact support.</Link>
      </>
    ) : (
      <>
        Please verify your credentials and try again, or{' '}
        <Link onClick={() => window.Atlas.chat.openWindow()}>contact support</Link>.
      </>
    );

  const [showError, setShowError] = useState(true);
  return (
    <>
      <Alert status={'error'} borderRadius={'lg'} mt={3}>
        <AlertIcon mb={2} />
        <Box width={'100%'}>
          <AlertTitle fontSize={'14px'}>{errorTitle}</AlertTitle>
          <AlertDescription fontSize={'14px'}>{errorDescription}</AlertDescription>
        </Box>
        <Button
          variant={'outline'}
          borderColor={'#BBBEC7'}
          color={'text2'}
          bg={'white'}
          _hover={{ borderColor: '#60687D' }}
          _active={{ bg: '#F4F4F6' }}
          onClick={() => setShowError(!showError)}
          my={'auto'}
          size={'sm'}
          borderRadius={'md'}
        >
          {showError ? 'Hide' : 'View'} Error
        </Button>
      </Alert>

      <Collapse in={showError}>
        <Flex
          border={'border1'}
          borderWidth={'1px'}
          width={'100%'}
          py={2}
          px={3}
          mt={3}
          borderRadius={'lg'}
        >
          <P2 width={'100%'} color={'text3'}>
            {JSON.stringify(error.message)}
          </P2>
        </Flex>
      </Collapse>
    </>
  );
};

export default ErrorDisplay;
